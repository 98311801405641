import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetProfileQuery } from '../redux/apiServices/userApi';

const AuthLayout = ({ children }) => {
    const navigate = useNavigate();
    let token = document.cookie?.split(';').map(cookie => cookie.trim().split('=')).find(cookie => cookie[0] === 'trustedSession');
    token = token ? token[1] : null;

    const { isLoading, data, error } = useGetProfileQuery(undefined, {
        skip: !token,
    });

    useEffect(() => {
        if (!token) {
            if (window.location.pathname !== '/login') {
                navigate('/login'); // Redirect to login if not authenticated
            }
            return;
        }

        if (!isLoading) {
            if (error) {
                navigate('/login'); // Redirect to login on API error
            } else if (data) {
                const role = data?.user?.role;

                if (window.location.pathname === '/login') {
                    // Redirect authenticated users away from login
                    if (role === 'owner') {
                        navigate('/dashboard'); // Owner goes to dashboard
                    } else {
                        navigate('/'); // Other roles go to /
                    }
                }
            }
        }
    }, [isLoading, error, data, navigate, token]);

    if (isLoading) {
        return (
            <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-20 h-20 border-4 border-turquoise-500 rounded-full animate-spin" />
            </div>
        );
    }

    return <>{children}</>;
};

export default AuthLayout;
