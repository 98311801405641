import './Login.css';
import logo from '../../Assets/logo.png';
import {useEffect, useState} from "react";
import {useAuth} from "../../Custom/useAuth";
import {signIn, username, welcome, passwordText, loading} from "../../Constants/translations";
import {useLoginMutation} from "../../redux/apiServices/userApi";
export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {setUser} = useAuth();
    const language = localStorage.getItem('language') || 1;
    const [login, {isLoading}] = useLoginMutation();
    const [error, setError] = useState('');
    const [invalidCount, setInvalidCount] = useState(0);
    const [accountLocked, setAccountLocked] = useState(false);

    useEffect(() => {
        localStorage.setItem('language', 1);
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        if(name === 'email') {
            setEmail(value);
        } else {
            setPassword(value);
        }
    }

    const handleLogin = () => {
        // messages in french
        if(!email || !password) return setError('Veuillez remplir tous les champs');
        if(email === "" || password === "") return setError('Veuillez remplir tous les champs');
        login({email, password}).unwrap().then(response => {
            if(response?.user?.Group){
                const groupedUser = {...response?.user, role: response?.user?.Group?.permission};
                setUser(groupedUser);
                if(groupedUser?.role === 'owner'){
                    window.location.href = "/dashboard"
                }else{
                    window.location.href = '/'
                }
            }else{
                setUser(response.user);
                if(response?.user?.role === 'owner'){
                    window.location.href = "/dashboard"
                }else{
                    window.location.href = '/'
                }
            }
            localStorage.setItem('merchant', response?.user?.merchantId);

            setError('')
        }).catch(error => {
            console.log(error);
            setError('Email ou mot de passe incorrect');
            setInvalidCount(invalidCount + 1);
        });
    }

    useEffect(() => {
        if(invalidCount >= 5) {
            setError('Compte bloqué. Veuillez contacter les administrateurs')
            setAccountLocked(true);
        }
    }, [invalidCount]);

    return (
        <div className="login-container">
            <div className="login-elements">

				<img src={logo} alt="logo" className="logo"/>
                <span className="welcome">{welcome[language]}</span>
                <input type="text" className="input" name="email" onChange={
                    handleChange
                } placeholder={username[language]}/>
                <input type="password" className="input" name="password" onChange={
                    handleChange
                } placeholder={passwordText[language]}/>
                {
                    error && <span className="error">{error}</span>
                }
                <button className="button"
                        onClick={handleLogin} disabled={accountLocked}
                >{isLoading ? loading[language] : signIn[language]}</button>
                
            </div>
        </div>
    )
}
